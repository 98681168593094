import React from 'react';
import SEO from '../components/SEO';
import PageLayout from '../components/PageLayout';

const NotFoundPage = () => {
  return (
    <PageLayout>
      <SEO title="404: Not Found" />
      <h1>Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </PageLayout>
  );
};

export default NotFoundPage;
